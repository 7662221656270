<template lang="pug">
  .border-none.outline-none.Acordion
    button(@click="selected = !selected").w-full.text-left.text-primary.flex
      p.font-xl {{ title }}
      span(
        :class="selected ? 'rotate-90-dk' : 'rotate-80-dk'"
      ).material-icons-outlined.ml-3.transition expand_more
    transition(name="selected")
      .contentAcordion(v-if="selected || opened")
        slot
</template>

<script>
export default {
  name: "Acordion",
  data() {
    return {
      selected: true,
    };
  },
  props: [
    "title",
    "opened"
  ],
}
</script>

<style scoped>
.rotate-90-dk {
  animation: rotationDK 0.5s forwards alternate;
}

.rotate-80-dk {
  animation: rotationDKMenus 0.5s forwards alternate;
}

@keyframes rotationDK {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes rotationDKMenus {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>